.wrapper-feedback-button {
	position: fixed;
	bottom: 30px;
	right: 30px;
	cursor: pointer;
	display: flex;
	justify-content: center;
	align-items: center;
	width: 48px;
	height: 48px;
	background: var(--primary);
	border-radius: 8px;
	box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);

	svg {
		font-size: 20px;
		color: #fff;
	}
}

@media (max-width: 480px) {
	.wrapper-feedback-button {
		right: 16px;
		bottom: 16px;
	}
}
