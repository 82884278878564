.ui-checkbox {
    font-size: var(--control-text-size-m);


    .ui-checkbox-toggle {
        min-width: 20px;
        min-height: 20px;
        margin-right: var(--space-xs);
        background: transparent;
        border: var(--control-border-width) solid var(--color-control-bg-border-default);
        border-radius: var(--control-radius);
    }


    &.checked {
        .ui-checkbox-toggle {
            border-color: var(--color-control-bg-primary) !important;
            background: var(--color-control-bg-primary) !important;

            svg {
                background-color: var(--color-control-bg-primary);
                color: var(--color-control-bg-primary);


                *[fill] {
                    fill: #fff;
                }
            }
        }
    }

    &.disabled {
        color: var(--color-control-typo-disable);

        .ui-checkbox-toggle {
            background-color: var(--color-control-bg-disable) !important;
            border-color: transparent !important;

            svg {
                background-color: transparent !important;

                *[fill] {
                    fill: var(--input-disabled-text) !important;
                }
            }
        }
    }
}