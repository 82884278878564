.section {
	background: var(--color-bg-default);
	height: max-content;
	border-radius: var(--border-radius);


	/*.ui-form .form-field label:first-child {
		font-size: 13px;
	}*/

	.content {
		padding: 20px;

	}

	header {
		background: var(--color-bg-system);
		color: var(---text);
		font-size: 1rem;
		font-weight: 500;
		padding: 0 20px;
		height: 40px;
		display: flex;
		align-items: center;
		border-radius: var(--border-radius) var(--border-radius) 0 0;
	}

	// cтили таблицы
	.sp-table {
		border: 1px solid var(--color-bg-system);
	}

}