.ui-form {
	&.no-form-submit {
		.form-submit {
			display: none;
		}
	}
}
.popup-transfer {
	.popup-transfer-wrapper {
		display: flex;
		flex-direction: column;
		gap: 20px;

		.popup-transfer-user {
			width: 400px;
		}

		.popup-btns {
			padding-top: 20px;
			display: flex;
			gap: 20px;
		}
	}
}
