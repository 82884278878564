.ReactDatePicker {
	display: flex;
	flex-direction: column;

	label {
		margin-bottom: var(--space-xs);
	}
}

.react-datepicker-wrapper {
	.react-datepicker__input-container {
		display: flex;
		align-items: center;



		input {
			padding: 12px;
			color: var(--color-control-typo-default) !important;
			height: var(--control-height-m);
			background: var(--color-control-bg-default) !important;
			border: var(--control-border-width) solid var(--color-control-bg-border-default) !important;
			font-family: var(--font-primary);
			font-size: 16px;
			border: none;
			width: 100%;

			&:disabled {
				background: var(--color-control-bg-disable) !important;
			}

			&:not(:disabled):not(:focus):hover {
				border-color: var(--color-control-bg-border-default-hover) !important;
				background: var(--color-control-bg-default);
			}

			&:focus {
				background: var(--color-control-bg-default) !important;
				border-color: var(--color-control-bg-border-focus) !important;
			}

			&.is-required {
				border-color: var(--color-typo-alert);
			}

			&::placeholder {
				color: var(--color-typo-ghost);
			}
		}

		svg+input,
		span+input {
			padding-left: 30px;
		}

		svg {
			font-size: 18px;
			color: var(--color-typo-primary);

			*[fill] {
				fill: var(--text-lighter);
			}

			*[stroke] {
				stroke: var(--text-lighter);
			}
		}
	}

	.react-datepicker__close-icon::after {
		background-color: var(--color-control-typo-placeholder) !important;
		color: var(--color-bg-default);
		width: 16px;
		height: 16px;
		font-size: 16px;
		display: flex;
		justify-content: center;
		align-items: center;
	}
}

.react-datepicker-popper {
	z-index: 2;

	.react-datepicker__triangle {
		display: none;
	}

	.react-datepicker {
		background-color: transparent;
		border-color: transparent;

		&__navigation-icon--previous,
		&__navigation-icon--next {
			top: 2px;
		}

		&__header {
			// background-color: var(--color-bg-default);
			background-color: var(--color-bg-system);
			border-bottom-color: var(--color-bg-system);
			text-transform: lowercase !important;

			.react-datepicker__current-month {
				padding-bottom: 8px;
			}

			&>* {
				color: var(--color-typo-primary);
			}

			&__day-name,
			&__current-month,
			&-time__header {
				color: var(--color-typo-primary);
			}

			.react-datepicker__day-names {
				background-color: var(--color-bg-default);

				&>* {
					color: var(--text-lighter);
				}
			}
		}

		&__month-container {
			background-color: var(--color-bg-default);
			border: var(--border-width) solid var(--border-color);
			border-radius: var(--input-border-radius);
			box-shadow: 5px 5px 15px rgba(#000, 0.1);

			.react-datepicker__week {
				.react-datepicker__day {
					// border-radius: 50%;
					color: var(--color-typo-primary);

					&--outside-month {
						color: var(--text-lighter);
					}

					&:hover {
						background-color: var(--primary20);
					}

					&--today {
						border: 1px solid var(--primary);
						border-radius: 0.3rem;
					}

					&--selected {
						background-color: var(--primary) !important;
						color: #fff !important;

						&:hover {
							background-color: var(--primary);
						}
					}

					&--keyboard-selected {
						background-color: transparent;
					}

					&--disabled {
						color: var(--color-typo-alert) !important;

						&:hover {
							background-color: transparent !important;
						}
					}
				}
			}
		}

		// TIME
		.react-datepicker__time-container {
			margin-left: 10px;
			border: var(--border-width) solid var(--border-color);
			border-radius: var(--input-border-radius);

			box-shadow: 5px 5px 15px rgba(#000, 0.1);

			.react-datepicker__time {
				background-color: var(--color-bg-default);
				color: var(--color-typo-primary);

				&-box {
					width: 100% !important;
				}

				.react-datepicker__time-list {
					.react-datepicker__time-list-item {
						height: unset;

						&:hover {
							background-color: var(--primary20);
						}

						&--selected {
							background-color: var(--primary);
							color: #fff;

							&:hover {
								background-color: var(--primary);
							}
						}

						&--disabled {
							display: none;
						}
					}
				}
			}
		}
	}
}