.auth-layout {
	width: 100%;
	height: 100%;
	display: flex;
	overflow: auto;

	.auth-background {
		flex: 1;
		height: 100%;
		position: relative;

		img {
			object-fit: cover;
			width: 100%;
			height: 100%;
		}

		.background-layout {
			position: absolute;
			top: 0;
			bottom: 0;
			right: 0;
			left: 0;
			background-color: var(--login-background-layout);
			backdrop-filter: blur(0.5px);
		}

		.slogan {
			text-transform: uppercase;
			position: absolute;
			bottom: 65px;
			left: 62px;
			display: flex;
			flex-direction: column;
			font-size: 84px;
			line-height: normal;
			gap: 20px;
		}
	}
}

@media (max-width: 480px) {
	.auth-layout {
		overflow: hidden;
		.auth-background {
			display: none;
		}

		.auth {
			flex-grow: 1;
			max-width: 100%;
		}
	}
}
