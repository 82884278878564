.dialogs-root {
	display: flex;
	flex-direction: column;
	gap: 20px;
	height: 100%;
	overflow-x: hidden;

	.head-page {
		color: var(--dark);
		font-size: 18px;
		font-weight: 700;
	}
}
