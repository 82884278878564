.user-select {
	.sp-dropdown-items {
		max-height: unset !important;
	}
}

@media (max-width: 767px) {
	.user-select {
		.sp-dropdown-items {
			max-height: 35vh !important;
			overflow-y: auto;
		}
	}
}
