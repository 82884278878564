.feature-value-selector {
	display: flex;
	align-items: center;
	gap: 5px;
	margin-bottom: 10px;
	
	.sp-btn + .sp-btn {
		margin: 0;
	}
	
	.feature-reset {
		width: 24px;
		height: 24px;
		color: var(--secondary);
		border-radius: 5px;
		cursor: pointer;
		
		* {
			transform-origin: center center;
			transform: scale(0.5);
		}
		
		&:hover {
			background: var(--secondary);
			color: var(--text-primary);
		}
	}
}
