.sp-portal.sp-trigger-popup {
	--calendar-day-other-text: var(--text-light);
	--calendar-day-disabled-text: var(--text-lighter);
	--date-picker-dropdown-bg: var(--color-bg-default);
	--date-picker-border-radius: var(--border-radius);
	--calendar-border-radius: var(--border-radius);
	--date-picker-tab-text: var(--text-lighter);
	--date-picker-secondary-bg: var(--content-bg);
	--date-picker-tab-bg: var(--content-bg);
	--date-picker-tab-hover-bg: var(--color-bg-default);
	--date-picker-dropdown-border-color: var(--border-color);
	--date-picker-dropdown-text: var(--color-typo-primary);

	.sp-date-drop-down {
		.current-mode {
			.sp-calendar {
				>div {
					&:not(.month-days) {
						background-color: var(--color-bg-default);
					}
				}

				.month-year {
					--calendar-control-color: var(--text-light);
				}
			}
		}

		.mode-switch {
			.active {
				background-color: var(--color-bg-default);
			}
		}
	}

	.sp-popover-content {
		.filters {
			.filter-header {
				.filter-item {
					color: var(--color-typo-primary);
				}
			}
		}

		.sp-btn.sp-btn-default.filter-apply {
			&:hover {
				span {
					color: var(--color-bg-default);
				}
			}
		}
	}
}