.BillingModules {
	display: grid;
	grid-template-columns: 1fr 1fr;
	gap: var(--space-s);

	.billing-module {
		display: flex;
		width: 100%;
		overflow: hidden;
		align-items: center;
		position: relative;
		background: var(--color-bg-default);
		border: var(--control-border-width) solid var(--color-bg-secondary);
		padding: var(--space-s);
		border-radius: var(--control-radius);
		transition: all var(--transition-time);
		cursor: pointer;

		.billing-module-text {
			width: 100%;
			display: flex;
			gap: var(--space-s);
			flex-direction: column;
			align-items: center;

			.billing-module-price-wrapper {
				display: flex;
				flex-direction: column;
				align-items: center;
			}
		}

		.billing-module-icon {
			position: absolute;
			top: -7px;
			right: -6px;

			svg {
				width: 13px;
				height: 10px;

				path {
					fill: #fff;
				}
			}
		}

		&:hover {
			border: var(--control-border-width) solid var(--color-control-bg-border-secondary);
		}
	}

	.billing-module-active {
		border: var(--control-border-width) solid var(--color-control-bg-border-secondary);

		&::before {
			content: '';
			position: absolute;
			top: -3px;
			right: -11px;
			transform: rotate(45deg);
			width: 31px;
			height: 15px;
			border: var(--control-border-width) solid var(--color-control-bg-border-secondary);
			background: var(--color-control-bg-border-secondary);
		}
	}

	.billing-payment-waiting-mode {
		border: var(--control-border-width) solid var(--color-bg-secondary);

		&::before {
			border: var(--control-border-width) solid var(--color-bg-secondary);
			background: var(--color-bg-secondary);
		}
		&:hover {
			border: var(--control-border-width) solid var(--color-bg-secondary);
		}
	}
}