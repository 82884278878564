.wrapper-feedback-form {
	background: var(--color-bg-secondary);
	padding: 24px;
	display: flex;
	flex-direction: column;
	align-items: flex-end;

	.content {
		width: 100%;

		form {
			width: 100%;

			.fields-wrapper {
				display: flex;
				flex-direction: column;
				gap: 24px;
				width: 100%;
			}

			.submits {
				margin-top: 24px;
			}
		}
	}

	.wrapper-close-popup {
		display: flex;
		justify-content: flex-end;
		cursor: pointer;
	}
}

@media (max-width: 767px) {

	.wrapper-feedback-form {
		height: 100%;
		width: 100%;
		max-width: unset;
		max-height: unset;
	}
}
