.BillingHeader {
	background: var(--color-bg-secondary);
	padding: var(--space-xl);
	width: 100%;

	.billing-header-statusline {
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding-bottom: var(--space-xl);
	}

	.billing-line {
		display: flex;
		justify-content: space-between;
		align-items: center;
	}

	.billing-line-wrapper {
		display: flex;
		flex-direction: column;
		gap: var(--space-s);
	}
}