.wrapper-single-application {
	display: flex;
	gap: 20px;
	align-items: flex-start;
	height: 100%;

	.left-application {
		flex-grow: 1;
		display: flex;
		flex-direction: column;
		gap: 20px;
		height: 100%;

		.wrapper-head-section {
			width: 100%;
			display: flex;
			justify-content: space-between;
			align-items: center;

			.type-application {
				font-size: 14px;
				font-weight: 400;
				color: var(--dark-grey);
			}
		}

		.section {
			&:has(.wrapper-history-widget),
			&:has(.wrapper-widget-messages) {
				display: flex;
				flex-direction: column;
				flex-grow: 1;
				overflow: hidden;

				.sp-tabs-content {
					flex-grow: 1;
					overflow: hidden;
				}
			}

			.main-info-section {
				flex-grow: 1;
				display: flex;
				flex-direction: column;
				gap: 16px;
				overflow: hidden;

				.wrapper-field {
					display: flex;
					flex-direction: column;
					gap: 20px;
				}

				.delimeter {
					height: 1px;
					background: var(--secondary-bg);
				}

				.editor-control {
					gap: 20px;
					display: flex;
				}

				.top-main-section {
					display: flex;
					flex-direction: column;
					gap: 16px;

					.row-field {
						display: flex;
						gap: 20px;
					}

					.form-field {
						margin: 0px;
					}
				}
			}
		}
	}

	.right-application {
		width: 592px;
		display: flex;
		flex-direction: column;
		gap: 20px;
		max-height: 100%;
		overflow: hidden;

		.relation-records-controls,
		.wrapper-new-employee {
			display: flex;
			gap: 24px;
			align-items: flex-end;

			.form-field {
				margin: 0px;
				flex-grow: 1;
			}
		}

		.relation-records-content {
			display: flex;
			flex-direction: column;
			gap: 24px;
			flex-grow: 1;
			overflow-x: hidden;
			overflow-y: auto;
		}

		.ui-form {
			.sp-row {
				margin: 0px;

				&.bottom-align {
					align-items: center;
				}

				.react-datepicker-wrapper {
					width: 100%;
				}

				.form-field {
					&.disabled {
						.react-datepicker__close-icon {
							display: none;
						}
					}
				}
			}

			.attachment-files {
				width: 100%;

				&.disabled {
					.upload {
						pointer-events: none;
						opacity: 0.6;
					}
				}

				.list {
					.delete-btn {
						display: none;
					}
				}
			}
		}
	}

	.title-section {
		font-size: 18px;
		font-weight: 700;
		color: var(--dark);
	}

	.controls {
		display: flex;
		gap: 20px;
	}
}

.with-new-record {
	.new-client {
		background: var(--secondary);
		color: var(--text-primary);

		&:hover {
			background: var(--secondary);
			color: var(--text-primary);
		}
	}
}

.wrapper-contragent-name {
	max-width: 400px;
}
.history-dialog {
	header {
		flex-shrink: 0;

		.title {
			width: 100%;
			display: flex;
			justify-content: space-between;
			align-items: center;
		}
	}

	.content {
		flex-grow: 1;
		overflow: hidden;
		display: flex;
		flex-direction: column;
	}
}
