.wrapper-dialogs-filters {
	.composite-toolbar {
		margin: 0px;

		.left {
			flex-grow: 1;
			align-items: center;
		}
	}
}
