.post-message-editor {
	display: flex;
	flex-direction: column;
	gap: 24px;

	.actions {
		display: flex;
		justify-content: flex-end;
		gap: 24px;

		#attach-files-message {
			display: none;
		}

		.upload {
			flex-shrink: 0;
		}

		.sp-btn-primary {
			flex-shrink: 0;
			svg {
				height: 24px;
				path {
					fill: #fff;
				}
			}
		}

		.attached-files {
			display: flex;
			flex-wrap: wrap;
			gap: 4px;

			.item-attached-file {
				display: flex;
				align-items: center;
				gap: 4px;
				padding: 4px 8px;
				border-radius: 4px;
				background: var(--light-grey);
			}
		}
	}
}
