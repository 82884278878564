.editable {
	display: inline-block;
	vertical-align: middle;
	//overflow: hidden;

	&.empty {
		.editable-value {
			text-decoration: underline;
			color: var(--secondary);
		}
	}

	&.textarea {
		.editable-value {
			span {
				line-height: 1.4em;
				padding: 6px;
			}
		}

		.submit {
			display: block;
			margin-top: 5px;
			margin-left: 0;
		}
	}

	.editable-value {
		display: block;
		cursor: pointer;
		border-radius: 4px;
		width: calc(100% - 40px);
		margin-left: -6px;

		span {
			display: block;
			min-height: 30px;
			line-height: 30px;
			padding: 0 6px;
		}

		&:hover span {
			//text-decoration: underline;
			background: var(--main-bg-hover);
		}

		& > svg {
			margin-left: 10px;

			* {
				fill: var(--secondary);
			}
		}
	}

	.submit {
		display: inline-block;
		margin-left: 5px;
	}

	input {
		width: calc(100% - 40px);
		height: 30px;
		line-height: 30px;
		margin-left: -6px;
	}
}
