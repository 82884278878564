.fixed-page {
	//max-width: calc(1400px - var(--menu-width));

	flex-grow: 1;
	display: flex;
	flex-direction: column;
	height: 100%;

	h1 {
		margin-bottom: 15px;
	}

	.ui-table {
		margin-left: -10px;
		overflow-x: auto;
	}
}

label {
	// здесь был "!important" - нигде его не используйте, лучше уточнить путь к классу (например .class .subclass label)
	color: var(--text-light);

	&.required {
		&::after {
			margin-left: 2px;
			display: inline-block;
			content: '*';
			color: #d00;
			transform: translate(1px, 0);
		}
	}
}