.label-field {
    overflow: hidden;
    word-break: break-all;
    text-overflow: ellipsis;

    label {
        color: var(--form-label-color) !important;
        font-size: 14px !important;

        position: relative;

        &.is-required:after {
            position: absolute;
            content: '*';
            color: var(--color-typo-alert);
            margin-left: 5px;
        }
    }

    span.text {
        font-size: 14px;
        line-height: 16px;
        font-weight: 500;
    }
}