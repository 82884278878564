.ToolbarListSelect {
    .Select-SelectContainer_multiple {
        .Select-ControlValueContainer {
            position: absolute;
        }

        .Select-ControlValue {
            display: flex;
            align-items: center;
            padding-top: unset !important;
        }

        .Status {
            // немного меньше из-за отступа от кружка
            width: calc(100% - 10px)
        }
    }
}