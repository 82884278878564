.Status {
    display: inline-flex;
    align-items: center;
    flex-direction: row;



    span {
        min-height: 14px;
        min-width: 14px;
        margin-right: 5px;
        border-radius: 50%;
    }
}

.StatusSelect {
    .Status {
        width: 100%;
    }
}