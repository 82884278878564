.sp-table {
	--table-hdr-font-size: 14px;
	--table-hdr-bg: var(--color-bg-system);
	--table-border-color: var(--content-bg);
	--table-cell-bg: var(--color-bg-default);
	--table-cell-hover-bg: var(--secondary-lighter);
	--table-cell-hover-text: var(--color-typo-primary);
	--table-cell-selected-bg: var(--secondary-light);
	--table-cell-selected-text: var(--color-typo-primary);
	--table-cell-padding: 10px;
	--table-error-text: var(--color-typo-alert);

	.labels {
		width: 100%;
		display: inline-flex;
		gap: var(--space-xs);
		flex-wrap: nowrap;
		overflow: hidden;
	}


	thead {


		tr {
			&:nth-child(2) {
				.project-name_border {
					border-radius: var(--border-radius) 0 0 0;
				}
			}

			&:last-child {
				.project-name_border {
					border-radius: 0 0 0 var(--border-radius);
				}
			}

			th {
				.column-header {
					.column-title {
						color: var(--color-typo-primary);
						font-size: 14px;
					}
				}

			}
		}
	}

	tbody {
		tr {
			&:nth-child(-n + 2) {
				td {
					&:first-child {
						border-radius: var(--border-radius) 0 0 0;
					}

					&:last-child {
						border-radius: 0 var(--border-radius) 0 0;
					}
				}
			}

			&:last-child {
				td {
					&:first-child {
						border-radius: 0 0 0 var(--border-radius);
					}

					&:last-child {
						border-radius: 0 0 var(--border-radius) 0;
					}
				}
			}

			td {
				height: 48px;
				font-size: 14px;

				&.cell-with-border {
					padding: 0;

					.string-value {
						height: 100%;
					}
				}

				.string-value {


					.avatars {
						flex-wrap: nowrap;
					}

					.project-name_border {
						min-width: 0;
						display: flex;
						align-items: center;
						height: 100%;
						border-left: 5px solid transparent;
						padding: 10px 10px 10px 16px;
					}

					.tasks-total-count {
						span {
							color: var(--text-lighter);
						}
					}
				}
			}
		}
	}
}

@media (max-width: 767px) {
	.table {
		overflow-x: auto;
		flex-grow: unset !important;

		.sp-table {
			table-layout: auto;
		}
	}
}