.KanbanListHeader {
    border-radius: var(--control-radius);
    padding: 0 var(--control-space-s);
    padding-right: var(--space-2xs);
    height: var(--control-height-m);
    width: var(--list-width) !important;
    margin-bottom: var(--space-m) !important;

    display: flex;
    align-items: center;
    justify-content: space-between;
    background: var(--color-bg-system);
    border-left: 5px solid grey;

    .actions {
        display: flex;
        flex-direction: row;
        align-items: center;
    }
}