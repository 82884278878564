.product-variant {
	border-top: 1px solid var(--border-color);
	padding: 15px 0;
	margin-bottom: 15px;
	display: flex;
	flex-direction: column;

	.product-nomenclature-wrapper {
		display: flex;
		align-items: flex-start;
		justify-content: space-between;
		gap: 40px;
		width: 100%;

		.wrapper-fields-nomenclature-card {
			display: flex;
			gap: 20px;

			label {
				.is-required {
					color: var(--color-typo-alert);
					font-size: 14px;
					margin-left: 3px;
				}
			}
		}
	}

	.image-wrapper {
		display: flex;
		gap: 20px;
	}

	label {
		display: block;
		font-size: 11px;
		color: var(--text-lighter);
	}

	.actions {
		padding-top: 29px;
		width: 130px;
		display: flex;
		flex-direction: column;
		gap: 100px;
	}

	.variant-image {
		width: 188px;
		height: 188px;
		display: flex;
		align-items: center;
		justify-content: center;
		margin-bottom: 10px;
		cursor: pointer;

		img {
			width: 100%;
			height: 100%;
			object-fit: cover;
			border: 1px solid transparent;

			&:hover {
				border: 1px solid var(--border-color);
			}
		}
	}

	label {
		font-size: 0.875rem;
	}

	.features {
		flex: 1.25;

		.feature-field {
			display: flex;
			gap: 10px;
			flex-direction: column;
			margin-bottom: 15px;
		}

		.feature-value {
			height: 28px;

			.sp-trigger.sp-dropdown {
				height: 100%;
			}

			.sp-btn+.sp-btn {
				margin: 0;
			}
		}
	}

	>.sp-trigger.sp-popover {
		margin-top: 20px;
	}
}