.wrapper-categories-catalog {
	display: flex;
	flex-direction: column;
	gap: 16px;
	margin-bottom: 20px;

	.sp-table-container {
		overflow: visible;
	}

	.wrapper-bottom-categories-catalog {
		display: flex;
		justify-content: space-between;
		align-items: center;

		.wrapper-select-showBy {
			display: flex;
			align-items: center;
			gap: 10px;

			.sp-dropdown {
				max-width: 60px;
			}
		}
	}

	.wrapper-tools-category {
		cursor: pointer;
		width: 50px;
		text-align: center;
		position: relative;

		& > svg {
			height: auto;
			width: 18px;
		}

		.wrapper-controls-list {
			display: flex;
			justify-content: center;
		}

		.controls {
			display: flex;
			flex-direction: column;
			position: absolute;
			background: var(--white);
			border: 1px solid var(--grey);
			
			border-radius: 5px;

			& > a > button {
				padding: 0px;
				border: none;
				background: none;
				justify-content: center;

				svg {
					height: 16px;
				}
			}

			.border-icon {
				padding: 0px;
				border: none;
				background: none;
				width: 20px;
				height: 20px;

				svg {
					height: 16px;
				}
			}
		}
	}

	.table-categories {
		.column-number {
			& > div {
				justify-content: center;
				text-align: center;
			}
		}

		.column-tools {
			& > div {
				overflow: visible;
			}
		}
	}
}
