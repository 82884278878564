.history-delta-popup {
	.sp-popup-content {
		min-height: 200px;
		background-color: var(--content-bg);
		max-width: 600px;
		max-height: 80vw;

		table {
			width: 100%;

			thead {
				th {
					background-color: transparent;
				}
			}

			tbody {
				td {
					text-align: center;
					background-color: var(--color-bg-default) !important;
					color: var(--color-typo-primary);
				}
			}
		}

		.owner {
			line-height: 30px;
			font-size: 12px;
			display: flex;
			gap: 5px;
			align-items: center;
			justify-content: center;
			margin-bottom: 5px;

			.date {
				font-style: normal;
				color: var(--secondary);
			}
		}
	}
}