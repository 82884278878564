.confirm-popup {
    display: flex;
    flex-direction: column;
    border-radius: 20px;

    .sp-popup-close-btn {
        top: 15px;
        right: 15px;
        border: 1px solid var(--text-lighter);
        background-color: var(--text-lighter);
        border-radius: 50%;
        opacity: 0.5;
    }

    .sp-popup-content {
        border-radius: 20px;
        padding: 50px 40px 40px 40px;
        flex-grow: 1;
        justify-content: center;
        display: flex;
        flex-direction: column;
        gap: 20px;
        max-width: 400px;

        .title {
            font-weight: bold;
            font-size: 24px;
            border-bottom: 0.5px solid var(--text-lighter);
            padding-bottom: 10px;
        }

        .buttons {
            display: flex;
            gap: 10px;
        }

        button {
            border-radius: 10px;
            width: max-content;
            margin-bottom: 0;
        }
    }


}