input,
textarea {
	font-family: var(--font-primary);
	font-size: var(--input-font-size);
}

textarea {
	border: var(--control-border-width) solid var(--color-control-bg-border-default);
}

.ui-input,.sp-suggest-input > input {
	padding: 12px;
	color: var(--color-control-typo-default) !important;
	height: var(--control-height-m);
	background: var(--color-control-bg-default) !important;
	border: var(--control-border-width) solid var(--color-control-bg-border-default);
	font-family: var(--font-primary);
	font-size: 16px;

	input {
		background-color: var(--color-control-bg-default) !important;
	}

	&:disabled {
		background: var(--color-control-bg-disable) !important;
	}

	&:not(:disabled):hover {
		border-color: var(--color-control-bg-border-default-hover);
		background: var(--color-control-bg-default);
	}

	&:focus {
		background: var(--color-control-bg-default);
		border-color: var(--color-control-bg-border-focus) !important;
	}

	&.is-required {
		border-color: var(--color-typo-alert);
	}

	&::placeholder {
		color: var(--color-typo-ghost);
	}
}

textarea {
	resize: none;
}

.form-field .number-value {
	background: var(--color-bg-default);
	border-color: var(--border-color);

	&:hover {
		border-color: var(--border-color);
	}
}