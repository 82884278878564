.kanban-skeleton {
	height: 100%;
	width: 100%;
	overflow: hidden;
	position: relative;




	.kanban-skeleton-container {
		width: 100%;
		overflow: auto;
		display: grid;
		grid-template-columns: repeat(4, var(--list-width));
		gap: 20px;
	}

	.skeleton-list-column {
		height: 100%;
		width: var(--list-width);

		.skeleton-list-header {
			width: 100%;
			height: var(--control-height-m);

			border-radius: var(--border-radius);
			margin-bottom: 10px;
		}

		.skeleton-tasks-container {
			display: flex;
			flex-direction: column;
			gap: 10px;
			width: 100%;
			overflow: hidden;

			.skeleton-task {

				height: 130px;
				width: 100%;
				border-radius: var(--border-radius);
			}
		}
	}
}