.comment {
	display: flex;
	flex-direction: column;

	.text {
		line-height: 1.4em;
		background: var(--color-bg-default);
		word-break: break-word;
		margin-bottom: 10px;

		p {
			margin: 0;
		}

		ol,
		ul {
			margin: 0;
			padding: 0 0 0 15px;
		}

		*+ol,
		*+ul,
		ol+*,
		ul+* {
			margin-top: 1.4em;
		}
	}

	.panel-buttons {
		display: grid;
		grid-template-columns: repeat(3, max-content);
		grid-column-gap: 10px;
		align-items: center;
		width: 70%;

		.toggle {
			gap: 10px;
		}

		>div {
			display: flex;
			align-items: center;
			margin: 0;
		}

		.errors-and-list {
			margin-top: 5px;
			flex: 1;
			order: 5;
			grid-column-start: 1;
			grid-column-end: 6;
			flex-direction: column;
			align-items: flex-start;

			.upload-error {
				display: flex;
				gap: 10px;
				justify-content: center;

				.icon {
					display: flex;
					width: 20px;
					align-items: center;

					svg {
						height: 16px;
					}
				}
			}
		}
	}
}




.big-image-popup-comment {
	img {
		min-width: 100%;
	}
}