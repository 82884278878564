.avatars {
	display: flex;
	flex-wrap: wrap;
	gap: 5px;
	align-items: center;

	> div {
		height: 100%;
	}
	.more {
		font-size: 1rem;
		color: var(--text-lighter);
	}
}

.member-avatar-popup {
	display: flex;
	white-space: nowrap;
	column-gap: 10px;
	align-items: center;
	padding: 100;

	.role {
		color: var(--text-lighter);
	}
}
