.Header {
	padding: var(--space-xs) var(--space-xl);
	background: var(--color-bg-brand);
	display: flex;
	justify-content: space-between;
	gap: var(--space-l);

	&-col {
		flex: 1;
		display: flex;
		align-items: center;

		&-end {
			display: flex;
			align-items: center;
			justify-content: flex-end;

			& > div {
				padding: 0px var(--space-m);
				border-right: 1px solid rgba(0, 65, 102, 0.2);
				height: 100%;

				&:last-child {
					border: none;
				}
			}
		}

		&-back-history {
			display: flex;
			align-items: center;
			gap: var(--space-xs);
		}
	}

	// окрашивает все контролсы в белое
	.Text,
	.IconSelect,
	.TextField,
	.Select-Placeholder,
	.TextField-ClearButtonIcon,
	.Select,
	.Button-Icon,
	input {
		color: white !important;

		::placeholder {
			color: white !important;
		}

		*[fill] {
			fill: white;
		}
	}
}
