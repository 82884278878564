.sp-popup {
	--popup-overlay-bg: var(--color-bg-tone);
	--popup-bg: var(--color-bg-default);
	--popup-text: var(--color-typo-primary);
	--popup-header-bg: var(--primary);
	--popup-header-text: var(--text-primary);
	--popup-border-radius: var(--border-radius);
	--popup-margin: 15px;
	--popup-padding: var(--space-2xl);
	--popup-header-height: 34px;
	box-shadow: var(--shadow-modal);

	&-content {
		border-radius: var(--border-radius);
		background-color: var(--color-bg-default);
	}
}