.attachments {
	display: flex;
	gap: 20px;
	align-items: center;

	&.upload-image {
		display: flex;
		flex-direction: column;
	}

	.download-all {
		.process-archive-attachments {
			display: flex;
			gap: 10px;
			align-items: center;
		}
	}

	.upload {
		margin: 8px 0;

		.attach-button {
			.border-icon {
				svg {
					*[fill] {
						fill: var(--primary);
					}
				}
			}
		}

		input[type='file'] {
			display: none;
		}

		input[type='file']+label {
			cursor: pointer;
			color: var(--secondary);
			color: var(--color-typo-primary);
			display: flex;
			align-items: center;
		}

		label>button:active {
			pointer-events: none;
		}
	}
}

.loading {
	pointer-events: none;
	opacity: 0.3;
}

.errors-and-list {
	.upload-errors {
		.new-file.upload-error {
			.icon {
				color: #d00;
			}
		}
	}
}

.popconfirm-content.confirm-delete {
	max-width: 300px;
	word-break: break-all;

	em {
		font-style: normal;
		color: #0af;
	}
}