.GlobarSearch {
	border-radius: var(--control-radius);
	background-color: rgba(255, 255, 255, 0.2);
	padding: 0 var(--space-xs);
	width: 100%;

	.FieldGroup {
		width: 100%;

		.Select {
			min-width: 100px;
			max-width: 100px;

			.Select-ControlValueContainer {
				margin-left: var(--space-2xs);
			}
		}

		.TextField-Icon {
			color: white !important;
		}

		.TextField-Input {
			&::placeholder {
				color: rgba(246, 251, 253, 0.35) !important;
			}
		}
	}
}

.GlobarSearch-popover {
	background-color: var(--color-bg-default);
	z-index: 1;
	max-width: 100%;
	display: flex;
	border-radius: calc(var(--scrollbar-size) / 2);
	box-shadow: var(--shadow-layer);
	border: var(--control-border-width) solid var(--color-control-bg-border-default);

	.Loader,
	.not-found {
		flex-grow: 1;
		display: flex;
		justify-content: center;
		align-items: center;
		padding: var(--space-m);
		height: 200px;
	}

	.popover-list {
		padding: var(--space-xs) 0px;
		max-height: 300px;
		overflow: auto;
		width: 100%;
		display: flex;
		flex-direction: column;
	}
}
