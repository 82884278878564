.ModuleMenu {
	overflow: hidden;

	.Collapse-Label {
		border-top: 1px solid var(--color-bg-stripe);
		box-shadow: 0px 0px 24px 0px rgba(0, 0, 0, 0.16);
		background: var(--color-bg-ghost);
	}

	.Collapse-LabelText {
		max-width: 100%;
	}

	.Collapse-Content {
		padding: 0px;
	}

	.module-menu-label {
		display: flex;
		flex-direction: column;
		align-items: center;
	}
}

.wrapper-tooltip {
	display: flex;
	gap: var(--space-xs);
}
