.MainLayout {
	display: flex;
	min-height: 100vh;
	max-height: 100vh;

	.SideBar {
		flex-shrink: 0;
	}

	&-right {
		flex-grow: 1;
		display: flex;
		flex-direction: column;
		overflow: hidden;
	}

	&-content {
		overflow: hidden;
		flex-grow: 1;
	}

	&-page {
		padding: var(--space-l);
		height: 100%;
		width: 100%;
		overflow: auto;
		background: var(--color-bg-secondary);
		transition: background var(--transition-timing);

		.page-title {
			margin: -15px -20px 15px -20px;
			padding: 0 20px;
			height: 50px;
			line-height: 50px;
		}
	}
}

@media (max-width: 767px) {
	.MainLayout {
		&-content {
			.page {
				padding: 15px;

				.page-title {
					margin: 0;
					padding: 0;
				}
			}
		}
	}
}
