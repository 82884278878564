.SideBar {
	position: relative;
	width: 240px;
	display: flex;
	flex-direction: column;
	background: var(--color-bg-default);
	transition: all var(--transition-time);

	a {
		text-decoration: none;
	}

	&-header {
		position: relative;
	}

	&-size-switcher {
		position: absolute;
		right: calc(var(--button-height) / -2);
		top: calc((100% - var(--button-height)) / 2);
	}

	&-logo {
		padding: var(--space-s) var(--space-m);
		display: flex;
		flex-shrink: 0;
		justify-content: center;
		align-items: center;
		height: var(--space-2xl);
		box-sizing: content-box;

		img {
			max-height: 100%;
		}
	}

	.SideBarMenu {
		flex-grow: 1;
		overflow: auto;
		scrollbar-width: none;
	}

	&-footer-theme-toggle-wrapper {
		padding: var(--space-s) var(--space-m);
		display: flex;
		align-items: center;
		justify-content: space-between;
		border-bottom: 1px solid var(--color-bg-stripe);
		border-top: 1px solid var(--color-bg-stripe);
	}

	&-footer-copyright {
		height: 48px;
		padding: var(--space-s) var(--space-m);

		.Text {
			color: var(--color-control-typo-disable);
		}
	}
}

.SideBar.short {
	width: 80px;

	.SideBar-footer-theme-toggle-wrapper {
		padding: var(--space-s) 0px;
		justify-content: center;
	}

	.SideBar-footer-copyright {
		padding: var(--space-s) 0px;

		.Text {
			text-align: center;
		}
	}
}