.ui-form {
	.sp-row {
		gap: 40px;
		margin-bottom: 15px;

		.Checkbox {
			padding-top: 34px;
		}

		.sp-col {
			width: auto;
			min-width: 190px;
			max-width: 380px;
			flex: 1;
			margin: 0;

			.form-field {
				width: 100%;

				input {
					transition: all var(--transition-time);
				}

				&.disabled {
					* {
						border: none;
					}
				}

				.filter-content {
					.item-tag {
						background: none;
						border: none;

						.remove-btn {
							display: none;
						}
					}
				}
			}

			textarea {
				font-size: 14px;
			}
		}
	}

	.form-field {
		&>label:first-child {
			display: flex;
			color: var(--form-label-color);
			font-size: 14px;
		}

		& .label-with-link {
			margin-bottom: 5px;

			a {
				font-size: 11px;
				margin-left: 10px;
			}
		}

		&>label {
			.sp-trigger {
				margin-left: 5px;
				cursor: pointer;
			}
		}

		&.field-with-create-link {
			.create-link {
				font-size: 11px;
				text-decoration: underline;
				cursor: pointer;
				margin-top: 5px;
			}
		}
	}
}

@media (max-width: 767px) {
	.ui-form {
		.sp-row {
			flex-direction: column;
			gap: 10px;

			.sp-col {
				min-width: 100%;
				flex: 1;
				width: 100%;

				.form-field {
					width: 100%;
				}

				textarea {
					font-size: 14px;
				}
			}
		}
	}
}

@media (max-width: 350px) {
	.ui-form {
		.sp-row {
			.sp-col {
				.dates {
					flex-direction: column;
				}
			}
		}
	}
}