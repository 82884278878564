.amount-ranges {

    .wrapper-amount-range {
        display: flex;
        flex-direction: column;
        gap: 10px;

        .amount-range {
            display: flex;
            align-items: flex-end;
            gap: 10px;

            .inputs {
                display: flex;
                align-items: flex-end;
                gap: 20px;
                flex-grow: 1;

                .input-block {
                    display: flex;
                    flex-direction: column;
                    width: 100%;

                    span {
                        color: var(--text-light);
                        margin-bottom: 8px;
                    }

                    &.invalid {

                        input {
                            color: var(--color-typo-alert);
                            border-color: var(--color-typo-alert) !important;
                        }
                    }
                }
            }

            .actions {
                width: 90px;
                display: flex;
                gap: 10px
            }
        }
    }
}