.ToolbarRecordSelect {
    .Select-SelectContainer_multiple {
        .Select-ControlValueContainer {
            position: absolute;
        }
        .Select-ControlValue {
            display: inline-flex;
            align-items: center;
            padding-top: unset !important;
        }
    }
}