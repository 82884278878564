.table-wrapper {
	max-width: 100%;
	margin-left: -5px;
	padding-left: 5px;

	table {
		transition: all var(--transition-time);

		&.loading {
			opacity: 0.5;
			filter: saturate(0);
		}
	}
}

@media (max-width: 767px) {
	.table-wrapper {
		max-width: 100%;
		overflow-x: auto;
		margin-left: -5px;
		padding-left: 5px;

		table.ui-table {
			table-layout: auto;
		}
	}
}
