.attachment-with-data {
	.info {
		display: flex;
		align-items: center;
		justify-content: space-between;
		gap: 20px;
		flex-wrap: wrap;

		.icon-name {
			width: 170px;

			.icon-title {
				svg {
					color: var(--svg-attachment-color);
				}

				span {
					color: var(--color-typo-primary);
				}
			}
		}

		.user {
			flex: 1;
			min-width: 10px;
		}

		.date {
			color: var(--grey);
		}

		.view-document {
			display: flex;
			align-items: center;

			svg {
				*[fill] {
					transition: all var(--transition-time);
					fill: var(--svg-attachment-color);
				}

				&:hover {
					*[fill] {
						fill: var(--color-typo-primary);
					}
				}
			}
		}

		.file-name {
			text-decoration: none;


			&:hover {
				color: var(--color-typo-primary);
			}


		}
	}
}