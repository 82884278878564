.sp-btn.icon-btn {
	border: none;
	padding: 0;
	--default-font-size: 14px;

	&:not(.with-text) {
		justify-content: center;
		align-items: center;
	}

	&.with-text {
		span {
			font-size: 1rem;
			font-weight: 500;
			color: var(--text-lighter);
			padding-right: 5px;
		}

		&.border {
			span {
				color: var(--color-typo-primary);
			}
		}
	}

	&.no-border {
		border: none;
		padding: 0;

		&:hover {
			background-color: unset;
		}
	}

	.border-icon {
		transition: all var(--transition-time);
		height: 100%;
		width: 40px;

		display: flex;
		justify-content: center;
		align-items: center;
		border: var(--border-width) solid var(--border-color);
		border-radius: var(--border-radius);

		svg {
			*[fill] {
				fill: var(--text-lighter);
			}
		}
	}

	&:disabled {
		background-color: transparent;

		.border-icon {
			border-color: var(--icon-disabled);

			*[fill] {
				fill: var(--icon-disabled);
			}
		}

		&.with-text {
			span {
				color: var(--icon-disabled);
			}
		}
	}

	&:hover {
		background-color: unset;

		svg {
			*[fill] {
				fill: var(--primary);
			}
		}

		span {
			color: var(--primary);
		}

		.border-icon {
			border-color: var(--primary);
		}
	}

	&.delete-btn {
		.border-icon {
			svg {
				*[fill] {
					fill: var(--text-lighter);
				}
			}
		}

		&:hover {
			background-color: unset;

			.border-icon {
				border-color: var(--color-typo-alert);

				svg {
					*[fill] {
						fill: var(--color-typo-alert);
					}
				}
			}
		}
	}
}


.check-button-icon:not(:disabled),
.save-button-icon:not(:disabled) {
	&:hover {
		--default-border: var(--primary) !important;
		background-color: var(--primary20) !important;
	}

	.border-icon {
		border-color: var(--primary) !important;
	}

	svg {
		[fill] {
			fill: var(--primary) !important;
		}
	}
}

.cancel-button-icon {
	&:hover {
		--default-border: var(--color-typo-alert) !important;
		--primary: var(--color-typo-alert) !important;
		background-color: var(--danger20) !important;
	}

	.border-icon {
		border-color: var(--color-typo-alert) !important;
	}

	svg {
		*[fill] {
			fill: var(--color-typo-alert) !important;
		}
	}
}