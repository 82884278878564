.ripple-container {
	--ripple-opacity: 0.15;

	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	pointer-events: inherit;
	overflow: hidden;
	border-radius: inherit;
	z-index: 0;

	.touch-ripple {
		transition: all 520ms;
		transform: scale(0.15);
		opacity: 0;
		background: currentColor;
		position: absolute;
		border-radius: 100%;
		animation: ripple 720ms forwards;
		animation-timing-function: ease-out;
		pointer-events: none;
	}

	@keyframes ripple {
		100% {
			transform: scale(3.2);
		}
	}
}

.ripple-wrapper {
	display: inline-flex;
	position: relative;
}

@media (max-width: 767px) {
	.ripple-container {
		.touch-ripple {
			transform: scale(0.4);
		}
	}
}
