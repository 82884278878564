.model-list {
    height: 100%;
    display: flex;
    flex-direction: column;
    padding-bottom: 10px;

    #table {
        flex-grow: 1;
    }
}


.sp-tabs.route {

    display: flex;
    flex-direction: column;

    .sp-tabs-container {
        min-height: 39px;
    }

    .sp-tabs-content {
        height: 100%;
        display: flex;
        flex-grow: 1;
        flex-direction: column;

        .fixed-page {
            height: 100%;
        }
    }
}