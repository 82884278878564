.button-group {
	display: flex;
	flex-wrap: nowrap;

	&>* {
		border: none !important;
	}

	>* {
		padding: 10px 14px !important;
	}

	.sp-btn-primary {
		text-transform: unset;
		font-weight: unset;
	}

	.sp-btn-default {
		--default-bg: var(--color-bg-default);
		--default-text: var(--color-typo-primary);
	}
}