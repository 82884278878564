.nomenclatures-actions {
	margin: 10px 0;
	display: flex;
	justify-content: space-between;
	align-items: center;
	gap: 15px;

	.nomenclatures-actions-wrapper {
		display: flex;
		align-items: center;
		gap: 15px;
	}
}

.popup-added-nomenclature {
	.popup-added-nomenclature-wrapper {
		width: 400px;
		height: 180px;

		display: flex;
		flex-direction: column;
		gap: 15px;
	}
}

.wrapper-nomenclature-value {
	display: flex;
	justify-content: space-between;
	width: 100%;
	gap: 20px;
	align-items: center;

	span {
		font-size: 12px;
	}
}
