.product-card {
	text-decoration: none;
	display: grid;
	grid-template-columns: 1fr 1.33fr max-content;
	gap: 40px;
	padding: 8px 12px;
	min-height: 130px;
	background-color: var(--color-bg-default);
	border-radius: var(--border-radius);
	cursor: pointer;

	&:hover {
		color: unset;
	}

	.product-info-variant-image {
		display: flex;
		gap: 40px;
		flex-basis: 500px;

		.variant-image {
			width: 115px;
			height: 115px;
			flex-shrink: 0;

			img {
				width: 100%;
				height: 100%;
				object-fit: cover;
			}
		}

		.product-info {
			display: flex;
			flex-direction: column;
			gap: 4px;
			color: var(--text-light);
			font-size: 13px;
			line-height: 15px;

			.product-name {
				font-size: 14px;
				color: var(--color-typo-primary);
			}

			.variants-count {
				color: var(--text-lighter);
			}

			.product-category {
				margin-top: 4px;
				color: var(--secondary);
			}
		}
	}

	.prices-and-controls {
		display: flex;
		gap: 20px;
		justify-content: flex-start;

		.delimeter {
			height: 100%;
			width: 1px;
			background: var(--border-color);
		}

		.prices-list {
			flex-grow: 1;

			.variant-prices {
				.variant-price {
					display: flex;
					justify-content: space-between;

					.range {
						color: var(--text-light);
					}

					.price {
						font-weight: bold;
					}
				}
			}
		}
	}
}