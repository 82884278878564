.sp-date-picker {
	--date-picker-text: var(--color-typo-primary);
	--date-picker-bg: var(--color-bg-default);
	--date-picker-secondary-text: var(--text-light);
	--date-picker-tab-text: var(--color-typo-primary);
	--date-picker-tab-bg: var(--content-bg);
	--date-picker-tab-hover-text: var(--color-typo-primary);
	--date-picker-tab-hover-bg: var(--color-bg-default);
	--date-picker-border-color: rgba(0, 0, 0, 0.15);
	--date-picker-hover-border-color: var(--primary);
	--date-picker-dropdown-bg: var(--color-bg-default);
	--date-picker-dropdown-border-color: var(--border-color);
	--date-picker-active-border-color: var(--primary);
	--date-picker-border-radius: 4px;
	--date-picker-shadow: var(--color-typo-primary);
	--date-picker-btn-text: var(--color-typo-primary);
	--date-picker-btn-bg: var(--deep-dark);
	--date-picker-btn-hover-bg: var(--dark);
	--date-picker-ui-font-size: 12px;

	--calendar-day-width: 3em;
	--calendar-day-height: 2.5em;
	--calendar-day-hover-text: var(--primary);
	--calendar-day-hover-bg: rgba(var(--primary-rgb), 0.2);
	--calendar-day-hover-border-color: var(--primary);
	--calendar-today-text: rgba(var(--primary-rgb), 0.9);
	--calendar-today-bg: rgba(var(--primary-rgb), 0.2);
	--calendar-today-border-color: rgba(var(--primary-rgb), 0.4);

	--clock-size: 18em;
	--clock-hover-text: var(--text-primary);
	--clock-hover-bg: var(--primary);
	--clock-selected-bg: var(--secondary);
	--clock-selected-color: var(--text-primary);
	--clock-minute-bg: rgba(var(--primary-rgb), 0.2);
	--clock-minute-text: var(--color-typo-primary);
	--color-small-minute-bg: var(--dark-grey);
	--color-small-minute-text: transparent;
	--clock-am-hour-bg: rgba(var(--primary-rgb), 0.2);
	--clock-am-hour-text: var(--color-typo-primary);
	--clock-pm-hour-bg: var(--color-bg-default);
	--clock-pm-hour-text: var(--color-typo-primary);
	--clock-value-bg: var(--primary);
	--clock-value-text: var(--text-primary);
	--clock-arrow-color: var(--primary);



	min-width: 145px;
	width: 100%;
	height: var(--control-height-m) !important;

	.mode-switch {
		background-color: var(--color-bg-system) !important;
	}

	border: var(--control-border-width) solid var(--color-control-bg-border-default);
	background-color: var(--color-control-bg-default);

	input {
		padding: 12px;
		color: var(--color-control-typo-default) !important;
		height: var(--control-height-m);
		background: var(--color-control-bg-default) !important;
		border: var(--control-border-width) solid var(--color-control-bg-border-default);
		font-family: var(--font-primary);
		font-size: var(--control-text-size-m) !important;

		&:disabled {
			background: var(--color-control-bg-disable) !important;
		}

		&:not(:disabled):hover {
			border-color: var(--color-control-bg-border-default-hover);
			background: var(--color-control-bg-default);
		}

		&:focus {
			background: var(--color-control-bg-default) !important;
			border-color: var(--color-control-bg-border-focus) !important
		}

		&.is-required {
			border-color: var(--color-typo-alert);
		}

		&::placeholder {
			color: var(--color-typo-ghost);
			font-size: var(--control-text-size-m) !important
		}
	}

	&.active {
		input {
			background: var(--date-picker-bg);

		}
	}

	.clear-btn {
		right: 10px;
		color: var(--color-control-typo-default);

		&:hover {
			color: var(--color-control-typo-default);
		}
	}
}

.sp-date-drop-down {
	--calendar-day-text: var(--color-control-typo-secondary);

	.mode-switch {
		background-color: var(--color-bg-system);
	}

	.hour {
		background-color: var(--color-bg-system) !important;
		color: var(--color-typo-primary) !important;
	}

	.over-value {
		background-color: transparent !important;
		color: var(--color-typo-primary) !important;
	}
}