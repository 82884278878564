.captcha-wrapper {
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	margin-top: 24px;

	.captcha {
		display: flex;
		gap: 20px;
		flex-direction: row-reverse;
		align-items: center;
		justify-content: start;
		padding: 15px 0;

		.reload {
				cursor: pointer;
		}
	}
}