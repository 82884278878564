.wrapper-kanban-card {
	display: flex;
	flex-direction: column;
	gap: 12px;

	.row-kanban-card {
		display: flex;
		gap: 18px;

		&.top {
			justify-content: space-between;

			p {
				font-size: 12px;
				color: var(--color-grey);
				line-height: 14px;
			}
		}

		&.head {
			p {
				font-size: 14px;
				line-height: 16px;
				font-weight: 500;
				color: var(--color-typo-primary);
			}
		}

		&.relation-records {
			flex-wrap: wrap;
			gap: 8px;

			span {
				padding: 4px 8px;
				font-size: 12px;
				line-height: 14px;
				border-radius: 4px;

				&.task {
					background: rgba(151, 71, 255, 0.2);
					color: rgb(151, 71, 255);
				}

				&.order {
					background: rgba(51, 206, 101, 0.2);
					color: rgb(51, 206, 101);
				}

				&.document {
					background: rgba(39, 132, 255, 0.2);
					color: rgb(39, 132, 255);
				}
			}
		}
	}
}