.wrapper-message {
	display: flex;
	align-items: flex-end;
	flex-direction: row-reverse;
	padding-right: 16px;

	.wrapper-avatar-message {
		width: 30px;
		height: 30px;
		border-radius: 50%;
		display: flex;
		justify-content: center;
		align-items: center;
		background: var(--content-bg);

		svg {
			max-width: 20px;
			max-height: 20px;
		}
	}

	.sp-avatar {
		flex-shrink: 0;
	}

	.triangle-message {
		position: relative;
		overflow: hidden;
		width: 16px;
		height: 16px;
		flex-shrink: 0;

		&:before {
			content: '';
			position: absolute;
			display: block;
			width: 50px;
			height: 50px;
			top: -32px;
			right: -33px;
			z-index: 2;
			background: var(--color-bg-default);
			border-radius: 50%;
		}

		&:after {
			border: none;
			content: '';
			display: block;
			border: 8px solid transparent;
			border-left: 8px solid var(--content-bg);
			border-bottom: 8px solid var(--content-bg);
			bottom: 0;
			right: 0;
			z-index: 1;
		}
	}

	.content-message {
		padding: 12px;
		border-radius: 6px 6px 0px 6px;
		background: var(--content-bg);
		min-width: 335px;
		max-width: 500px;
		display: flex;
		flex-direction: column;
		gap: 4px;
		overflow: hidden;

		.wrapper-attachments {
			border-top: 1px solid var(--dark-grey);
			padding-top: 8px;
		}

		.date {
			font-size: 12px;
			font-weight: 400xp;
			color: var(--dark-grey);
			text-align: right;
		}

		.system-text {
			font-size: 12px;
			font-style: italic;
		}
	}

	&.is-me {
		flex-direction: row;
		padding-right: 0px;

		.triangle-message {
			position: relative;
			overflow: hidden;
			width: 16px;
			height: 16px;
			flex-shrink: 0;

			&:before {
				content: '';
				position: absolute;
				display: block;
				width: 50px;
				height: 50px;
				top: -32px;
				left: -33px;
				z-index: 2;
				background: var(--color-bg-default);
				border-radius: 50%;
			}

			&:after {
				content: '';
				display: block;
				border: 8px solid transparent;
				border-right: 8px solid var(--secondary-bg);
				border-bottom: 8px solid var(--secondary-bg);
				bottom: 0;
				right: 0;
				z-index: 1;
			}
		}

		.content-message {
			border-radius: 6px 6px 6px 0px;
			background: var(--secondary-bg);
		}
	}
}