.sp-popover-popup {
	--popover-bg: var(--color-bg-default);
	--popover-text: var(--color-typo-primary);
	border: var(--border-width) solid var(--border-color);
	padding: var(--space-xs);

	.sp-popover-arrow {
		&::after {
			border: var(--border-width) solid var(--border-color);
		}
	}

	.sp-popconfirm-buttons {
		display: flex;
	}
}