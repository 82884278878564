:root {
	// везде используйте эту переменную для определения ширины карточки канбана
	--list-width: 280px;
}

.kb-container {
	.kb-list-info {
		display: none !important;
	}

	.kb-list {
		margin-right: 20px;

		.hdr {
			height: var(--ui-filter-height) !important;
			text-transform: uppercase;
			padding: 0 10px;
			font-size: 14px;
			font-weight: 700;
			margin-bottom: 10px;
			border: none;

			.name {
				color: var(--kanban-header-text);
			}

			.actions {
				color: var(--kanban-header-text);
				font-weight: bold;

				&>* {
					display: flex;
					align-items: center;
					margin-left: 5px;
				}

				svg {
					width: 18px;
					height: 18px;

					*[fill] {
						fill: var(--kanban-header-text);
					}
				}
			}
		}

		.kb-items {
			.kb-item {

				position: relative;
				z-index: 0;
				color: unset;
			}
		}
	}
}

/*
	Этот класс не должен быть исключительно внутри .kb-container > .kb-list > .kb-items > .kb-item,
	потому что при перетаскивании элемент канбана выпадает из .kb-container и попадает в body > #root > #dnd-context
*/

.kb-item-content {
	border: none;
	background: var(--color-bg-default);
	border-radius: var(--border-radius);
	border-left: 5px solid;
	font-size: 13px;
	position: relative;
	padding: 8px 10px;
	box-shadow: none;
	filter: drop-shadow(0px 0px 3px rgba(0, 0, 0, 0.1));


	.labels {
		gap: 5px;
		display: flex;
		flex-wrap: wrap;
	}
}

.kb-new-item {
	background: var(--color-bg-default);
	border-color: var(--border-color);
	border-radius: var(--border-radius);
	max-width: calc(var(--list-width));
	border-left-width: 5px;


	textarea {
		&:hover {
			border-color: var(--border-color);
		}
	}

	.actions {
		button {
			color: var(--text-light);

			&:first-child {
				margin-right: 10px;
			}
		}
	}
}