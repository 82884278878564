.BillingModal {
	right: 160px;
	top: 60px;
	display: flex;
	align-items: center;
	max-height: 95%;
	flex-direction: column;
	min-height: 400px;
	width: 340px;
	
	.billing-body {
		padding: var(--space-l);
		display: flex;
		flex-direction: column;
		gap: var(--space-l);
	}

	> .Loader {
		margin-top: 200px;
	}
}

@media (max-width: 768px) {
	.BillingModal {
		right: unset;
		top: unset;
		width: 100%;
		height: 100%;
		max-width: unset;
		max-height: unset;
		border-radius: unset;
	}
}