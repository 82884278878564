.wrapper-widget-messages {
	overflow: hidden;
	display: flex;
	flex-direction: column;
	height: 100%;
	max-height: 100%;

	.wrapper-messages {
		margin-bottom: 24px;
		display: flex;
		flex-grow: 1;
		flex-direction: column;
		gap: 26px;
		overflow-x: hidden;
		overflow-y: auto;
	}
}
