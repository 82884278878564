$size-delete-btn: 20px;

@mixin hover-styles-uploader {
	.ImageUploader-placeholder {
		display: flex;
		z-index: 1;
		background: rgba(0, 0, 0, 0.3);
	}

	img {
		border: 1px solid var(--border-color);
	}

	.DeleteIconButton {
		display: flex;
	}
}

.ImageUploader {
	background: var(--color-control-bg-default);
	width: 188px;
	height: 188px;
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
	position: relative;
	padding: 0px;
	border-radius: 4px;
	border: 1px solid var(--color-control-bg-border-default);

	.ImageUploader-placeholder {
		position: absolute;
		width: 100%;
		height: 100%;
		justify-content: center;
		align-items: center;
		display: none;
		white-space: normal;
		text-align: center;
		color: var(--white);
		line-height: 1em;
		font-size: 1em;
	}

	.DeleteIconButton {
		justify-content: center;
		align-items: center;
		position: absolute !important;
		bottom: 0px;
		z-index: 2;
		display: none;
		padding: 5px;

		button {
			min-width: unset;
			width: $size-delete-btn;
			height: $size-delete-btn;

			svg {
				width: 1em;
				height: 1em;
			}
		}
	}

	&:hover {
		@include hover-styles-uploader;
	}

	img {
		width: 100%;
		height: 100%;
		object-fit: cover;
		border: 1px solid transparent;
	}
}

body:has(.popup-confirm-uploader) {
	@include hover-styles-uploader;
}
