.renderUser {
    .User {
        max-width: unset;

        &:hover {
            background-color: transparent !important;
        }

        .Text {
            max-width: unset;
        }
    }
}

.UserSelect {
    .Select-ControlValue {
        width: 100%;
    }

    .renderUser {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 100%;

        .UserAvatar {
            height: 100%;
        }
    }


}