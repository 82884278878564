.tox.tox-tinymce {
	border-radius: var(--border-radius);
	border: none;

	.tox-editor-container {
		border: var(--border-width) solid var(--border-color);
		border-radius: var(--border-radius);

		.tox-editor-header {
			.tox-toolbar--scrolling {
				background-color: var(--border-color);
			}

			.tox-toolbar-overlord {
				background: none;

				.tox-toolbar__primary {
					background: none;
					background: var(--color-bg-system);

					.tox-toolbar__group {
						border: none;
					}
				}
			}
		}

		.tox-sidebar-wrap {
			.tox-edit-area {
				iframe {
					background-color: var(--color-bg-default);
				}
			}
		}

		.tox-menubar {
			background: none;

			button {
				span {
					color: var(--text-light);
				}
			}
		}
	}

	.tox-statusbar {
		display: none;
	}
}