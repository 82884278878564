.badge-title {
	user-select: none;

	.title {
		position: relative;

		.icon-title {
			height: 20px;
		}

		.badge {
			color: #fff;
			position: absolute;
			display: flex;
			left: calc(100% + 3px);
			top: -6px;
			font-size: 12px;
			height: 20px;
			min-width: 20px;
			width: max-content;
			align-items: center;
			justify-content: center;
			border-radius: 10px;
			padding: 0 6px;
			text-transform: none;
			background-color: var(--color-bg-normal);

			&.beta {
				background-color: var(--warning);
			}

			&.old {
				background-color: var(--color-typo-alert);
			}
		}
	}
}