.input-password {
	font-size: 13px;

	.password-field {
		position: relative;

		> .icon-btn {
			position: absolute !important;
			right: 10px;
			bottom: 0;
		}
	}
}

.password-with-validate {
	.invalid {
		svg {
			path {
				fill: red !important;
			}
		}
	}
	
	.wrapper-password-hint {
		display: flex;
		flex-direction: column;
		justify-content: center;
		gap: 4px;
		margin-top: 10px;

		.item-hint-password {
			display: flex;
			gap: 4px;
			align-items: center;
			font-size: 12px;
			svg {
				path {
					fill: green;
				}
			}
		}
	}

	.field {
		margin: 0px;
	}
}
