.MobileHeader {
	background: var(--color-bg-brand);
	padding: var(--space-2xs) var(--space-m);
	justify-content: space-between;

	svg {
		fill: white !important;
	}

	// окрашивает все контролсы в белое
	.Text,
	.IconSelect,
	.TextField,
	.Select-Placeholder,
	.TextField-ClearButtonIcon,
	.Select,
	.Button-Icon,
	input {
		color: white !important;

		::placeholder {
			color: white !important;
		}

		*[fill] {
			fill: white;
		}
	}
}
