.amount-ranges {
	display: flex;
	gap: 10px;
	flex-direction: column;


	.actions {
		display: flex;
		gap: 10px;
	}

	.records>div {
		margin-bottom: 5px;
	}
}