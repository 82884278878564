.post-comment-v2 {
    .actions {
        margin-top: 10px;
        display: flex;
        gap: 10px;
    }

    input {
        width: 100%;
    }
}