.icon-title {
	display: flex;
	align-items: center;
	gap: 10px;

	svg {
		>* {
			transition: all var(--transition-time);
		}
	}

	span {
		transition: color var(--transition-time);
	}

	&.due-date {
		&.expired {
			color: var(--color-typo-alert);

			svg {
				*[stroke] {
					stroke: var(--color-typo-alert);
				}
			}
		}

		&.expiring-soon {
			color: var(--warning);

			svg {
				*[stroke] {
					stroke: var(--warning);
				}
			}
		}
	}
}