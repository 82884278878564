.wrapper-rewrite-password {
	display: flex;
	flex-direction: column;
	gap: 16px;
	max-height: 100%;
	overflow: auto;

	.head {
		display: flex;
		align-items: center;
		gap: 12px;
		flex-wrap: wrap;
		justify-content: center;

		.form-rewrite-password {
			width: 100%;
		}

		svg {
			cursor: pointer;
		}
	}
	.error {
		margin-top: 16px;
	}
	.user-section {
		display: flex;
		gap: 12px;
		justify-content: center;
	}

	.form-rewrite-password {
		& > div {
			display: flex;
			flex-direction: column;
			gap: 10px;

			.form-field {
				margin: 0px;
			}
		}
	}
}
