.SearcResultItem {
	width: 100%;
	padding: var(--space-xs) var(--space-s);
	display: flex;
	gap: var(--space-xs);
	align-items: center;
	max-width: 100%;

	&:hover {
		cursor: pointer;
		background: var(--color-control-bg-clear-hover);
	}

	&-icon {
		width: var(--graphics-size-l);
		height: var(--graphics-size-l);
		display: flex;
		align-items: center;
		justify-content: center;
		flex-shrink: 0;
	}

	.Text {
		line-height: 100%;
	}

	&-info {
		display: flex;
		flex-direction: column;
		gap: var(--space-3xs);
		flex-grow: 1;
		overflow: hidden;

		.mark {
			color: var(--color-typo-secondary);
		}
	}
}
