.wrapper-list-dialog {
	flex-grow: 1;
	display: flex;
	flex-direction: column;

	.wrapper-count-record {
		display: flex;
		gap: 4px;
		font-size: 12px;
	}
}
