.default-theme {
	//*scss переменные - основные

	$dark: #323232;
	$white: #ffffff;
	$grey: #aeb2b7;
	$dark-grey: #7c7d84;
	$light-grey: #ecf3f9;
	$light-blue: #d8e8ff;
	$new-blue: #b0d4ff;
	$primary: #0078d2;
	$primary20: rgba(0, 120, 210, 0.8);
	$secondary: #2784ff;
	$skeleton-bg: #dfe6ec;
	$dark-color-white: #ececec;
	$success: #{$primary};
	$danger: rgb(205, 65, 65);
	$danger20: rgba(rgb(205, 65, 65), 0.2);
	$info: #2784ff;
	$warning: #ff9900;

	//*scss переменные - производные

	$hover-danger-button: rgba($danger, 0.1);

	$login-background-layout: rgba(0, 32, 51, 0.5);
	$main-bg-hover: rgba($secondary, 0.05);
	$skeleton-shine: rgba($light-grey, 0.6);
	$button-default-hover-bg: rgba($new-blue, 0.3);
	$input-disabled-bg: #dce5ed;

	//*css переменные - основные цвета
	--color-white: #{$dark-color-white};
	--dark: #{$dark};
	--dark-grey: #{$dark-grey};
	--grey: #{$grey};
	--white: #{$white};
	--white_rgb: 255, 255, 255;
	--light-grey: #{$light-grey};
	--light-blue: #{$light-blue};
	--new-blue: #{$new-blue};
	--primary: #{$primary};
	--primary20: #{$primary20};
	--secondary: #{$secondary};

	--success: #22c38e;
	--danger: #{$danger};
	--danger20: #{$danger20};
	--info: #{$info};
	--warning: #{$warning};

	//*css переменные - произвоные переменные

	--skeleton-shine: #{$skeleton-shine};
	--skeleton-bg: #{$skeleton-bg};

	--button-hover-default-bg: #{$button-default-hover-bg};

	--text: var(--dark);
	--text-light: var(--dark-grey);
	--text-lighter: var(--grey);
	--text-primary: var(--white);
	--text-minimised: var(--dark);

	--pager-text: var(--dark-grey);
	--pager-disabled: var(--grey);

	--main-bg: var(--white);
	--main-bg-opacity70: rgba(var(--white_rgb), 0.7);
	--main-bg-hover: #{$main-bg-hover}; //?!
	--content-bg: var(--light-grey);
	--secondary-bg: var(--light-blue);

	--primary-rgb: 51, 206, 101;
	--secondary-light: var(--light-blue);
	--secondary-lighter: var(--light-blue);

	--border-color: var(--new-blue);

	--shadow: 0 1px 3px rgba(#000, 0.15);

	--icon-border: var(--secondary);
	--icon-fill: var(--light-blue);
	--icon-content: var(--primary);
	--icon-border-inactive: var(--icon-border);
	--icon-content-inactive: var(--dark-grey);
	--icon-disabled: var(--grey);

	--menu-width: 240px;

	--kanban-header-text: var(--white);
	--kanban-card-text: var(--grey);
	--kanban-parent-rgb: 236, 243, 249;

	--calendar-background: var(--white);
	--calendar-text-color: var(--dark);
	--calendar-bg-weekend: #f4f6f8;

	--scrollbar-thumb: rgba(79, 8, 8, 0.1);
	--scrollbar-thumb-hover: rgba(0, 0, 0, 0.3);
	--scrollbar-track: transparent;

	--input-bg: var(--white);
	--input-disabled-bg: #{$input-disabled-bg};
	--input-text: var(--color-typo-primary);
	--input-disabled-text: var(--dark-grey);

	--dropdown-text: var(--text-light);
	--dropdown-bg: var(--white);
	--dropdown-disabled-bg: var(--input-disabled-bg);

	--placeholder: var(--dark-grey);

	--svg-attachment-color: var(--grey);

	--form-label-color: var(--dark-grey);

	--toggle-background: var(--primary);

	--section-header-color: var(--light-blue);

	--login-background-layout: #{$login-background-layout};

	background: var(--main-bg);

	--table-hdr-bg: var(--white);
	--table-ftr-bg: var(--white);
	--table-hdr-text: var(--grey);
	--table-cell-text: var(--dark-grey);
	--table-tr-all-bg: var(--new-blue);
	--table-tr-system-bg: var(--danger20);

	// --hover-danger

	//?используется в ui-old
	--dropdown-bg: var(--main-bg);

	--warn-message-bg: #fffcf0;

	--breadcrumbs-color: var(--dark-grey);
}