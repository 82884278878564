.fast-create-contragent {
	.warning-message {
		color: var(--error);
		font-size: 11px;
	}
	.full-width {
		.sp-col {
			min-width: 100% !important;
		}
	}
	.wrapper-additional-text {
		display: flex;
		align-items: center;
		gap: 10px;
		margin-bottom: 16px;

		.additional-text {
			font-size: 11px;
			line-height: 1.4em;
		}
	}
	.ui-form {
		.sp-row {
			margin: 0px;

			.form-field {
				& > div {
					width: 100%;
				}
			}
		}
	}
}

.sp-suggestions-list {
	.suggestion-item {
		height: auto;
	}
}
