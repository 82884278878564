.OnlineUsers {
    display: flex;
    flex-direction: row;
    gap: var(--space-s);
    align-items: center;

    .Text {
        text-wrap: nowrap;
    }

    .Avatar {
        box-shadow: unset;
    }
}