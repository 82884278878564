.popup-confirm-block-user{
  min-width: 250px;
  max-width: 250px;
  min-height: 100px;

  .sp-popup-content{
    text-align: center;
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  .wrapper-control-confirm{
    display: flex;
    gap: 10px;
    justify-content: center;

    button{
      min-width: 50px;
      justify-content: center;
    }
  }
}