.BillingTotal {
	display: flex;
	flex-direction: column;
	gap: 12px;

	.billing-total-line {
		padding: 23.5px 0;
		display: flex;
		width: 100%;
		justify-content: space-between;
	}

	.billing-waiting-line {
		display: flex;
		width: 100%;
		justify-content: center;
	}

	.billing-buttons {
		display: flex;
		flex-direction: column;
		gap: 16px;
	}
	
	.payment-waiting-text {
		display: flex;
		align-items: center;
		gap: 8px;
	}
}