.breadcrumbs {
	margin-bottom: 10px;
	display: flex;
	justify-content: flex-start;
	align-items: center;
	gap: 2px;

	.link {
		text-decoration: none;
		font-size: 14px;
		font-weight: 400;
		line-height: 16px;
		color: var(--breadcrumbs-color);
	}

	.delimiter {
		path {
			fill: var(--breadcrumbs-color);
		}
	}
}
