.sp-btn.sp-btn-default {
	&.delete-btn {
		&.with-icon {
			gap: 20px;

			svg {
				*[fill] {
					fill: var(--color-typo-alert) !important;
				}
			}

			span {
				color: var(--color-typo-alert) !important;
			}
		}

		&:hover {
			border-color: var(--color-typo-alert);

			svg {
				*[fill] {
					fill: var(--color-typo-alert) !important;
				}
			}

			span {
				color: var(--color-typo-alert) !important;
			}
		}
	}
}

.add-row {
	padding: 10px 0;
	width: 100%;
	text-align: center;
	cursor: pointer;


	&:hover {
		.add-btn {
			transform: scale(1.1);
		}
	}

	#bg {
		fill: var(--color-bg-default);
	}

	.add-btn {
		border: 1.6px solid var(--primary);
		border-radius: 50%;
		filter: drop-shadow(0 2px 3px rgba(#000, 0.15));
		transition: all var(--transition-time);
	}

	&.disabled {
		pointer-events: none;


		svg {
			filter: saturate(0);
		}
	}
}