.product-page {
	display: flex;
	justify-content: space-between;
	gap: 20px;

	.product-information-variants {
		flex: 2;
		display: flex;
		flex-direction: column;
		gap: 20px;

		.type-measure{

			.sp-row{
				margin-bottom: 0px;
			}
		}
	}

	.product-additional-information {
		display: flex;
		flex: 1;
		flex-direction: column;
		gap: 20px;

		section {
			header {
				justify-content: space-between;
			}
		}

		.description {
			display: grid;
			grid-template-rows: 1fr;
			transition: all var(--transition-time);

			>div {
				overflow: hidden;
			}

			.rich-text {
				margin-bottom: 10px;
			}
		}

		.toggle {
			margin-top: 10px;
			display: flex;
			gap: 20px;
		}
	}


	.main-information {
		display: flex;
		flex-direction: row;
		gap: 20px;

		.left {
			flex-basis: 400px;
		}

		.right {}
	}

	.product-features {
		display: grid;
		grid-template-rows: 1fr;
		transition: all var(--transition-time);

		>div {
			overflow: hidden;
		}

		.feature-selector,
		.feature-value {
			margin-bottom: 12px;
		}

		.sp-btn+.sp-btn {
			margin-left: 5px;
		}
	}

	.amount-ranges-section {
		display: grid;
		grid-template-rows: 1fr;
		transition: all var(--transition-time);

		>div {
			overflow: hidden;
		}
	}

	.closed {
		grid-template-rows: 0fr !important;
	}
}

.feature-value {
	display: flex;
	flex-direction: column;
	gap: 8px;
	color: var(--text-light);

	.record-select {
		width: 100%;
	}
}

.tech-road-map{

}