.render-bankAccount {
	display: flex;
	justify-content: space-between;
	width: 100%;

	.bank {
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
		min-width: 30%;
		max-width: 60%;
	}
}

.render-contragent {
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 100%;

	.name {
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
		min-width: 30%;
		max-width: 90%;
	}
}

.render-status {
	display: inline-flex;
	align-items: center;

	.color {
		height: 10px;
		width: 10px;
		margin-right: 5px;
		border-radius: 50%;
	}
}

.render-project {
	color: var(--text-light);
}
