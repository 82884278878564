.range-date-picker {
	display: flex;
	flex-direction: row;
	gap: 10px;

	.TextField,
	.Select {
		min-width: 140px;
		width: 140px;
	}
}