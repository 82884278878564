.pricelist-catalog {
	display: flex;
	gap: 20px;

	&__left {
		flex-basis: 300px;
		max-width: 300px;
	}

	&__right {
		flex: 1;

		.suggestions-popup {
			z-index: 5;
		}

		.toolbar-search {
			width: 400px;

			.icon {
				[data-icon='xmark'] {
					transition: color var(--transition-time);
					cursor: pointer;

					&:hover {
						color: var(--secondary);
						border-radius: 50%;
					}
				}
			}
		}
	}
}
