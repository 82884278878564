.BillingSpaces {
	.billing-spaces-line {
		display: flex;
		justify-content: space-between;
		align-items: center;
		width: 100%;
	}

	.billing-spaces-input {
		margin-top: 8px;
	}

	.billing-space-range {
		margin-top: 12px;
	}

	.billing-space-line-wrapper {
		display: flex;
		justify-content: space-between;
		align-items: center;
		width: 100%;
	}
}