.notifications {
	display: flex;
	flex-direction: column;
	gap: 10px;
	position: fixed;
	top: 75px;
	right: 75px;
	z-index: 5000;
	font-size: 14px;
	align-items: flex-end;

	.notification {
		padding: 12px 16px;
		border-radius: 4px;
		color: var(--white);
		cursor: pointer;
		display: flex;
		flex-direction: row;
		align-items: center;
		gap: 20px;

		&-animation-basic {
			opacity: 0;
			animation: notification 17s !important;

			@keyframes notification {

				0% {
					opacity: 0;
				}

				10% {
					opacity: 1;
				}

				90% {
					opacity: 1;
				}

				100% {
					opacity: 0;
				}
			}

		}

		&-animation-no-fade-out {
			animation: noFadeOutNotification 1s;

			@keyframes noFadeOutNotification {
				0% {
					opacity: 0;
				}

				100% {
					opacity: 1;
				}
			}


		}

		&>p {
			margin: 0;
		}

		&-success {
			background-color: var(--color-bg-brand);
		}

		&-error {
			background-color: var(--color-typo-alert);
		}

		&-danger {
			background-color: var(--color-typo-alert);
		}

		&-warning {
			background-color: var(--color-typo-warning);
		}


		&-info {
			background-color: var(--info);
		}

		.controls {
			display: flex;
			gap: 12px;
		}

	}
}