.online-badge {
  position: relative;
  display: inline-flex;

  &>* {
    margin: 0;
    padding: 0;
  }

  .online-flag {
    // белая обертка
    position: absolute;
    content: '';
    background-color: #fff;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;

    .is-online,
    .is-offline {
      width: 80%;
      height: 80%;
      border-radius: 50%;
    }

    .is-online {
      background-color: var(--color-bg-success);
    }

    .is-offline {
      background-color: var(--color-typo-alert);
    }
  }
}