.variant-prices {
	flex: 1;

	.ranges {
		display: grid;
		grid-template-columns: auto minmax(80px, 1fr);
		row-gap: 10px;
		column-gap: 20px;
		font-size: 1rem;

		.value {
			display: flex;
			gap: 5px;
			align-items: center;

			input {
				width: 100%;
				height: 28px;
				padding: 0 5px;
			}
		}
	}
}
