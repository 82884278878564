@use 'drop-down';
@use 'button';
@use 'date-picker';
@use 'scrollbars';
@use 'form';
@use 'table';
@use 'popup';
@use 'input';
@use 'tabs';
@use 'button-group';
@use 'checkbox';
@use 'kanban';
@use 'avatar';
@use 'pager';
@use 'editor';
@use 'popover';
@use 'calendar';

.date-picker .react-datepicker-popper {
	z-index: 1500;
}

button.btn + button.btn {
	margin-left: 10px;
}

.sp-portal {
	z-index: 9000;
}
