.feature-values-block {
	font-size: 12px;
	color: var(--text-lighter);

	.feature-value-selector {
		display: flex;
		align-items: center;
		gap: 5px;
		margin-bottom: 10px;
		flex-wrap: wrap;

		.base-block-button.not-selected {
			&.pseudo-disabled {
				background-color: var(--disabled-bg);
				border-color: transparent;

				span {
					color: var(--disabled-text);
				}
			}
			&:hover {
				border-color: var(--info-bg);

				span {
					color: var(--info-bg);
				}
			}
		}

		.sp-btn-info {
			border-width: var(--border-width);

			&:hover {
				filter: contrast(100%);
			}
		}

		.sp-btn + .sp-btn {
			margin: 0;
		}

		.feature-reset {
			width: 24px;
			height: 24px;
			color: var(--secondary);
			border-radius: 5px;
			cursor: pointer;

			* {
				transform-origin: center center;
				transform: scale(0.5);
			}

			&:hover {
				background: var(--secondary);
				color: var(--text-primary);
			}
		}
	}
}
