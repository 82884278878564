@use 'reset.css';
@use 'hover.css';
@use 'themes';
@use 'common';
@use 'ui/index';
@use 'consta';

$error: #d00;

html,
body,
#root {
	height: 100vh;
	font-size: 14px;

	#dnd-context {
		height: 100%;
	}
}

.tox-editor-header {
	z-index: 0 !important;
}

.warn-message {
	background: var(--warn-message-bg);
	padding: 10px;
	border: 1px dashed rgba(0, 0, 0, 0.2);
	width: auto;
	display: inline-block;
}

:root {
	--ui-height: 36px;
	//переменная должна иметь значение чистое (без едениц измерения) для корректного расчета высоты в em
	--ui-height-no-px: 36;
	--ui-filter-height: 36px;
	//переменная должна иметь значение чистое (без едениц измерения) для корректного расчета высоты в em
	--ui-filter-height-no-px: 36;
	--ui-filter-margin: 20px;
	--ui-comment-subject-color: #323232;
	--ui-toolbar-padding-bot: 8px;
	--ui-filter-font-size: 1em;
	--toolbar-gap-column: 20px;
	--toolbar-gap-row: 10px;
	--margin-top-small-screen: 15px;
	--border-radius: 4px;
	--border-width: 1.6px;
	--scrollbar-size: 10px;
	--error: #{$error};
}

#root {
	overflow: hidden;
}

body {
	font-family: 'Roboto', sans-serif;
	line-height: 1.4em;
	color: var(--color-typo-primary);
	transition: color var(--transition-time);
}

h1 {
	font-size: 1.2rem;
}

button.btn+button.btn {
	margin-left: 10px;
}

.error {
	color: var(--color-typo-alert);
}

a {
	color: var(--color-typo-primary);
	transition: color var(--transition-time);

	&.create-link {
		display: flex;
		cursor: pointer;
		margin-top: 3px;
		color: var(--primary);
		font-size: 12px;
		text-decoration: underline;

		&:hover {
			color: var(--primary);
		}
	}
}

hr {
	height: 1px;
	border: 0;
	background: #ddd;
	margin-bottom: 15px;
}

.placeholder {
	font-size: 14px;
	color: var(--text-lighter) !important;
}

@media (max-width: 991px) {
	.page-title {
		display: none;
	}
}

#table {
	height: 100%;
}

span {
	&.isRequired {
		&:after {
			content: '*';
			position: absolute;
			color: var(--color-typo-alert);
			font-weight: bold;
			margin-left: 2px;
		}
	}
}

.color-value {
	width: 18px;
	height: 18px;
}

ul {
	margin: 0;
	padding: 0;
}