.sp-btn {
	&.base-block-button {
		line-height: normal;
		display: inline-flex;
		justify-content: center;
		align-items: center;
		padding: 10px 24px;
		overflow: hidden;
		gap: 10px;
		margin: 0;


		&.with-icon {
			padding: 0 10px;
		}
	}

	&.filter-button {
		width: max-content;
		text-transform: none !important;
		font-weight: normal !important;
		border-width: var(--border-width) !important;
		svg {
			width: 20px;
			height: 20px;
		}
	}
}